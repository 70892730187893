import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { isValid } from 'date-fns'
import moment from 'moment'
import { AppConfig } from 'environment'
import * as dateLocales from 'react-date-range/dist/locale'

const { enUS: dateEnUS, es: dateEs, de: dateDe, nb: dateNb, fr: dateFr, it: dateIt, sv: dateSv, pl: datePl } = dateLocales

require('moment/locale/es')
require('moment/locale/pl')
require('moment/locale/fr')
require('moment/locale/fr-ch')
require('moment/locale/it')
require('moment/locale/de')
require('moment/locale/de-ch')
require('moment/locale/sv')

const nbOverrides = require('./assets/locales/no-NO.json')
const enUs = require('./assets/locales/en-US.json')
const esEs = require('./assets/locales/es-ES.json')
const noNo = require('./assets/locales/no-NO.json')
const deDe = require('./assets/locales/de-DE.json')
const deCh = require('./assets/locales/de-CH.json')
const frFr = require('./assets/locales/fr-FR.json')
const itIt = require('./assets/locales/it-IT.json')
const plPl = require('./assets/locales/pl-PL.json')
const svSe = require('./assets/locales/sv-SE.json')
const usAndNo = { ...enUs, ...nbOverrides }

const commonNS = 'ui'
export const i18nResources = {
  en: { 
    ...enUs, 
    ...dateEnUS 
  },
  'en-US': { 
    ...enUs, 
    ...dateEnUS 
  },
  es: { 
    ...esEs, 
    ...dateEs 
  },
  'es-ES': { 
    ...esEs, 
    ...dateEs 
  },
  no: { 
    ...noNo, 
    ...dateNb 
  },
  'no-NO': { 
    ...noNo, 
    ...dateNb 
  },
  de: { 
    ...deDe, 
    ...dateDe 
  },
  'de-DE': { 
    ...deDe, 
    ...dateDe 
  },
  'de-CH': { 
    ...deCh, 
    ...dateDe 
  },
  fr: { 
    ...frFr, 
    ...dateFr 
  },
  'fr-FR': { 
    ...frFr, 
    ...dateFr 
  },
  it: { 
    ...itIt, 
    ...dateIt 
  },
  'it-IT': { 
    ...itIt, 
    ...dateIt 
  },
  nb: { 
    ...usAndNo, 
    ...dateNb 
  },
  'nb-NO': { 
    ...usAndNo, 
    ...dateNb 
  },
  nn: { 
    ...usAndNo, 
    ...dateNb 
  },
  'nn-NO': { 
    ...usAndNo, 
    ...dateNb 
  },
  pl: { 
    ...plPl, 
    ...datePl 
  },
  'pl-PL': { 
    ...plPl, 
    ...datePl 
  },
  sv: { 
    ...svSe, 
    ...dateSv 
  },
  'sv-SE': { 
    ...svSe, 
    ...dateSv 
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: i18nResources,
    debug: !!AppConfig.ENABLE_I18N_DEBUG,
    fallbackLng: {
      nb: ['no'],
      nn: ['no'],
      'nb-NO': ['no'],
      'no-NO': ['no'],
      'nn-NO': ['no'],
      default: ['en-US']
    },
    defaultNS: [commonNS],
    nsSeparator: ['.'],
    interpolation: {
      escapeValue: false, // React already safe from xss
      format: (value, formatStr) => {
        if (isValid(new Date(value))) {
          return moment(value)
            .locale(i18n.language)
            .format(formatStr)
        }

        return value
      }
    },
    detection: { order: ['localStorage'] }
  })
  .then(() => {
    let language = (i18n.language || 'en-US').substring(0, 2)
    moment.locale(language)
  })

function getResourceFormat(instance, key) {
  let resource

  // We may not have translations for the language detected
  // so get the first resource that can be found.
  // SEE: https://www.i18next.com/overview/api#languages
  for (const lang of instance.languages) {
    const res = instance.getResource(lang, commonNS, key)
    if (res) {
      resource = res
      break
    }
  }

  const args = resource.split(',')
  if (args.length !== 2) {
    return ''
  }
  return args[1].replace('}}', '').trim()
}


export default i18n
export { getResourceFormat}