export const appConfig = {
  appBaseTitle: window['env'].APP_BASE_TITLE,
  defaultUnits: window['env'].DEFAULT_UNITS,
  mapboxAccessToken: window['env'].MAPBOX_ACCESS_TOKEN,
  mapboxUsername: window['env'].MAPBOX_USERNAME,
  mapboxLightMapId: window['env'].MAPBOX_LIGHT_MAP_ID,
  mapboxBasicMapId: window['env'].MAPBOX_BASIC_MAP_ID,
  enableI18nDebug: window['env'].ENABLE_I18N_DEBUG,
  gaTrackingId: window['env'].GA_TRACKING_ID,
  recaptchaKey: window['env'].RECAPTCHA_KEY,
  recaptchaV2Key: window['env'].RECAPTCHA_V2_KEY,
  apiHost: window['env'].API_HOST,
  debugMode: window['env'].DEBUG_MODE,
  googlemapsApiToken: window['env'].GOOGLEMAPS_API_TOKEN
}
